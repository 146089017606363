import React from "react";
// import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/layout.js"



export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};


// WRAP WITH GLOBALPROVIDER TO GET CONTEXT GOING
export const wrapRootElement = ({ element }) => (
  <div>{element}</div>
);