import React from "react"
import "../assets/fonts/lux-fonts/lux-fonts.css";
import "../styles/reset.css";

export default function Layout({ children }) {
  return (
    <div className="site-wrapper overflow-hidden">
        {children}
    </div>
  )
}